<template>
  <div>
    <h2 class="title-style pt-3">
      <v-icon class="icon-style">
        mdi-file-multiple-outline
      </v-icon> Contract Items
    </h2>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <br>
      <v-col
        class="text-right table-top-border"
      >
        <v-btn
          color="#000"
          :disabled="!isContractFromPartner"
          @click="showAddContractItemsForm = true"
        >
          <v-icon class="mr-2">
            mdi-plus-circle-outline
          </v-icon>
          Add New
        </v-btn>
      </v-col>
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              dense
              label="Search"
              append-icon="mdi-magnify"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-title>
      <p
        class="info-text"
      >
        <span v-if="partnerCheck()">*Indicates from partner account<span class="division-style">|</span></span>
        <v-icon
          color="#37474F"
        >
          mdi-arrow-up-thin
        </v-icon>Indicates a change order
      </p>
      <v-data-table
        :headers="headers"
        :items="contractItemsList"
        :search="search"
        disable-sort
        class="elevation-1 table-contracts"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-tooltip
                v-if="item.deleted_at !== null"
                top
              >
                <template v-slot:activator="{ on,attrs }">
                  <span
                    v-bind="attrs"
                    class="deleted-items"
                    v-on="on"
                  >
                    {{ item.name }}
                  </span>
                </template>
                <span>Deleted At: <br> {{ item.deleted_at | convertToLocal }}</span>
              </v-tooltip>
              <span v-if="item.deleted_at === null">{{ item.name }}</span>
              <span
                v-if="item.database === false"
                class="partner-project"
              >*</span><v-icon
                v-if="isAnyChangeOrder(item.original_amount, item.projected_budget, item.projected_cost)"
                color="#C62828"
              >
                mdi-arrow-up-thin
              </v-icon>
            </td>
            <td>{{ item.quantity }} <span class="hrs-style">Hrs</span></td>
            <td><span class="currency-style">{{ projectContractDetails.project?.currency }}</span> {{ item.unit_cost }}</td>
            <td><span class="currency-style">{{ projectContractDetails.project?.currency }}</span> {{ item.original_amount }}</td>
            <td>
              <font
                v-if="item.status !== null"
                class="status-style"
                :color="getStatusColor(item.status)"
              >
                {{ item.status | statusFormat }}
              </font><font v-else>
                N/A
              </font>
            </td>
            <td class="premium">
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                :disabled="item.deleted_at !== null || subscriptionCheck('trial')"
                @click.stop
                @click="getContractItemCheckIns(item.id)"
              >
                <v-icon>mdi-account-eye-outline</v-icon>
              </v-btn>
              <span
                v-if="(subscriptionCheck('trial'))"
                class="premium-container"
              >
                <v-icon class="premium-style">
                  mdi-crown-circle
                </v-icon>
              </span>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                :disabled="item.database === false || item.deleted_at !== null"
                @click.stop
                @click="deleteContractItem(item.id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <add-contract-items
      v-if="showAddContractItemsForm"
      @closed="showAddContractItemsForm = false"
      @success="getProjectContractDetails"
    />
    <delete-contract-item
      v-if="showDeleteDialog"
      :title="'Delete this project contract ?'"
      :message="'Action will permanently remove this contract item from this project, Do you want to continue ?'"
      @closed="showDeleteDialog = false"
      @confirmed="confirmDelete"
    />
    <contract-item-check-ins
      v-if="showContractItemCheckIns"
      :contract-item-id="contactItemId"
      @closed="showContractItemCheckIns = false"
    />
  </div>
</template>

<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import AddContractItems from './AddContractItems';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import Constants from 'src/constants';
  import ContractItemCheckIns from './ContractItemCheckIns.vue';
  import global from 'src/mixins/global';
  import moment from 'moment';

  export default {
    name: 'ContractItemsList',
    components: {
      'centre-spinner': spinner,
      'add-contract-items': AddContractItems,
      'delete-contract-item': DeleteDialog,
      'contract-item-check-ins': ContractItemCheckIns,
    },
    filters: {
      statusFormat (val) {
        if (val === 'active') {
          return 'Active*';
        } else if (val === 'completed') {
            return 'Completed*';
        } else if (val === 'pending') {
          return 'Pending';
        } else {
          return val;
        }
      },
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    mixins: [global],
    data () {
      return {
        headers: [
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Quantity', value: 'quantity' },
          { text: 'Unit Cost', value: 'unit_cost' },
          { text: 'Amount', value: 'amount' },
          { text: 'Status', value: 'status' },
          { text: 'Check-Ins', value: 'check-ins' },
          { text: 'Delete', value: 'delete' },
        ],
        loading: false,
        search: '',
        showAddContractItemsForm: false,
        showDeleteDialog: false,
        contractItemId: '',
        showContractItemCheckIns: false,
        contractItemsList: [],
        contactItemId: '',
      };
    },
    computed: {
      projectContractDetails () {
        return this.$store.getters['projects/getProjectContractDetails'];
      },
      isContractFromPartner () {
        if (Object.keys(this.projectContractDetails).length > 0 && this.projectContractDetails.database === true) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
      this.loading = true;
      await this.subscriptionCheck('active') && await this.getProjectContractDetails();
      this.loading = false;
    },
    methods: {
      back () {
        const projectId = this.$route.params.projectId;
        this.$router.push(`/projects/${projectId}/details`);
      },
      async getProjectContractDetails () {
        this.loading = true;
        const projectContractId = this.$route.params.contractId;
        await this.$store.dispatch('projects/fetchProjectContractDetails', {
          projectContractId: projectContractId,
        }).then(() => {
          this.contractItemsList = this.projectContractDetails.budget_items;
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      getStatusColor (status) {
        if (status === 'completed' || status === 'active') {
          return '#2E7D32';
        } else {
          return '#C62828';
        }
      },
      deleteContractItem (id) {
        this.contractItemId = id;
        this.showDeleteDialog = true;
      },
      async confirmDelete () {
        this.loading = true;
        this.showDeleteDialog = false;
        await this.$store.dispatch('projects/deleteContractItem', {
          contractItemId: this.contractItemId,
          contractId: this.$route.params.contractId,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Contract item deleted successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.getProjectContractDetails();
        }).catch(() => {
          this.loading = false;
        });
      },
      getContractItemCheckIns (id) {
        this.contactItemId = id;
        this.showContractItemCheckIns = true;
      },
      isAnyChangeOrder (originalBudget, projectedBudget, projectedCost) {
        if (originalBudget !== projectedBudget || originalBudget !== projectedCost) {
          return true;
        }
        return false;
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC;
}
.v-data-table {
  background-color: #ECEFF1
}
.project-list-card {
  box-shadow: none !important;
}
.status-style {
  font-weight: bold;
}
.currency-style {
  color: #FF3700;
  font-family: 'Times New Roman', Times, serif;
  font-size: 12px;
}
.cost-items {
  margin-bottom: 2px;
}
.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  margin-left: 15px;
  margin-top: -20px;
}
.hrs-style {
  color: #37474F;
  font-family: 'Times New Roman', Times, serif;
  font-size: 11px;
  text-transform: capitalize;
}
.partner-project {
  color: #FF3700;
  font-weight: bold;
}
.deleted-items {
  text-decoration: line-through;
}
.division-style {
  margin-left: 8px;
  margin-right: 5px;
}
.tabs-contract-details{
  margin-left: 40px;
}
.contract-header-sec{
  padding-bottom: 50px;
  border-bottom: 1px solid #ccc;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-contracts {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.contract-list-card {
  box-shadow: none !important;
}
.table-contracts tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-contracts tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.action-btn {
  background: #fff !important;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.premium-style {
  font-size:25px;
  color:#006883!important;
  font-weight:bold;
}
.premium-container{
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left:0;
}
.premium{
  position: relative;
}
</style>

<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-file-sign
        </v-icon>Add Contract Items
      </h2>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-card-text class="text-body-1">
            <p class="my-p-style">
              Add New Contract Items <br><br>
              <span class="wrk-hrs-sub">Select all budget items from this list that you want to associate with this contract.</span>
            </p>
          </v-card-text>
          <v-row
            class="fill-height ml-2 mr-2"
          >
            <v-col
              cols="12"
              md="12"
            >
              <v-autocomplete
                v-model="selectedBudgets"
                class="field-style"
                :items="projectBudgets"
                :rules="[(v) => !!v || 'This field is required']"
                item-text="name"
                item-value="id"
                label="Select Budgets*"
                single-line
                append-icon="mdi-file-document-outline"
                outlined
                multiple
                dense
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              @click="addContractItems()"
            >
              Add
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aaa"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'AddContractItems',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        showModal: true,
        loading: false,
        valid: false,
        selectedBudgets: [],
      };
    },
    computed: {
      projectBudgets () {
        const budgetsList = this.$store.getters['projects/getProjectBudgets'];
        const filtredBudgetsList = budgetsList.filter(i => i.contract_status === false && i.database === true);
        return filtredBudgetsList;
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      await this.getProjectBudgets();
    },
    methods: {
      async getProjectBudgets () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchProjectBudgets', {
          projectId: this.$route.params.projectId,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      async addContractItems () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please select atleast one budgets from the list before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        await this.$store.dispatch('projects/addContractItems', {
          contractId: this.$route.params.contractId,
          details: {
            budget_items: this.selectedBudgets,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Contract budgets added successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$emit('success');
          this.loading = false;
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  color: #aaa;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.wrk-hrs-sub {
  font-size: 16px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.field-style {
  margin-top: -20px;
}
</style>

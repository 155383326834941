<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-btn
      color="#aaa"
      class="my-back-btn-style"
      text
      x-large
      dark
      @click="back()"
    >
      <v-icon
        left
        dark
      >
        mdi-arrow-left
      </v-icon>
      Back
    </v-btn>
    <div v-if="showContractDetails">
      <p class="project-header">
        Project Name:- <font
          class="project-name"
        >
          {{ projectContractDetails.project?.name }}
        </font>
      </p>
      <div class="project-progress-header">
        <p class="project-progress-title">
          Contract Progress:-
          <v-progress-linear
            class="progress-bar-style"
            color="#71DE9D"
            height="20"
            rounded
            dark
            :value="projectContractDetails.contract_progress"
          >
            <template>
              <strong
                v-if="projectContractDetails.contract_progress !== null"
                class="percentage-style"
              >{{ Math.ceil(projectContractDetails.contract_progress) }}%</strong>
              <strong v-else>0%</strong>
            </template>
          </v-progress-linear>
        </p>
      </div>
    </div>
    <centre-spinner
      :loading="loading"
    />
    <v-row class="mt-5">
      <v-col cols="9">
        <v-row class="mb-5">
          <v-col cols="3">
            <div class="budget-card-small budget-card-small-one">
              <div class="d-flex align-center">
                <div class="budget-card-small-icon budget-card-small-one-icon">
                  <v-icon>mdi-currency-usd</v-icon>
                </div>
                <div>
                  <h4>Projected Budget</h4><h1 v-if=" projectContractDetails.projected_budget !== null">
                    {{ projectContractDetails.projected_budget }} <span class="currency-style">{{ projectContractDetails.project?.currency }}</span>
                  </h1> <h1 v-else>
                    N/A <span class="currency-style">{{ projectContractDetails.project?.currency }}</span>
                  </h1>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="budget-card-small budget-card-small-two">
              <div class="d-flex align-center">
                <div class="budget-card-small-icon budget-card-small-two-icon">
                  <v-icon>mdi-currency-usd</v-icon>
                </div>
                <div>
                  <h4>Projected Cost</h4><h1 v-if="projectContractDetails.projected_cost !== null">
                    {{ projectContractDetails.projected_cost }} <span class="currency-style">{{ projectContractDetails.project?.currency }}</span>
                  </h1> <h1 v-else>
                    N/A <span class="currency-style">{{ projectContractDetails.project?.currency }}</span>
                  </h1>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="budget-card-small budget-card-small-three">
              <div class="d-flex align-center">
                <div class="budget-card-small-icon budget-card-small-three-icon">
                  <v-icon>mdi-currency-usd</v-icon>
                </div>
                <div>
                  <h4>Variance Total</h4><h1 v-if="projectContractDetails.variance_total !== null">
                    {{ projectContractDetails?.variance_total }} <span class="currency-style">{{ projectContractDetails.project?.currency }}</span>
                  </h1><h1 v-else>
                    N/A <span class="currency-style">{{ projectContractDetails.project?.currency }}</span>
                  </h1>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="budget-card-small budget-card-small-four">
              <div class="d-flex align-center">
                <div class="budget-card-small-icon budget-card-small-four-icon">
                  <v-icon>mdi-clock-time-eight-outline</v-icon>
                </div>
                <div><h4>Timesheet Hours</h4><h1> {{ projectContractDetails?.time_sheet_hours }} Hrs</h1></div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-tabs
          v-model="tabs"
        >
          <v-tab>
            <v-icon class="mr-2">
              mdi-file-multiple-outline
            </v-icon>
            Contract Items
          </v-tab>
          <v-tab>
            <v-icon class="mr-2">
              mdi-account-check-outline
            </v-icon>
            Check Ins
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <contract-items-list />
          </v-tab-item>
          <v-tab-item>
            <contract-check-ins />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="3">
        <v-row
          class="cost-items"
        >
          <v-col
            cols="12"
            md="12"
          >
            <v-card
              class="contract-card"
              color="ECEFF1"
            >
              <v-card-title class="mb-2">
                Contract Details
              </v-card-title>
              <p class="contract-content">
                Contract Name:- <font
                  class="font-my-style"
                >
                  {{ projectContractDetails?.name }}
                </font>
              </p>
              <p class="contract-content">
                Supplier:- <font
                  v-if="projectContractDetails.company !== null"
                  class="font-my-style"
                >
                  {{ projectContractDetails.company?.name }}
                </font>
                <font
                  v-else
                  class="font-my-style"
                >
                  N/A
                </font>
              </p>
              <p class="contract-content">
                Main Contract:- <font
                  v-if="projectContractDetails.main_contract !== null"
                  class="font-my-style"
                >
                  {{ projectContractDetails.main_contract?.name }}
                </font>
                <font
                  v-else
                  class="font-my-style"
                >
                  N/A
                </font>
              </p>
              <p class="contract-content">
                Original Amount:- <font
                  v-if="projectContractDetails.original_budget !== null"
                  class="font-my-style"
                >
                  <span class="currency-style">{{ projectContractDetails.project?.currency }}</span> {{ projectContractDetails?.original_budget }}
                </font>
                <font
                  v-else
                  class="font-my-style"
                >
                  N/A
                </font>
              </p>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-card
              class="contract-date-card"
              color="ECEFF1"
            >
              <v-card-title class="mb-2">
                Contract Duration
              </v-card-title>
              <p class="contract-content">
                Start Date:- <font
                  v-if="projectContractDetails.main_contract !== null"
                  class="font-my-style"
                >
                  <span v-if="projectContractDetails.main_contract?.start_date">
                    {{ projectContractDetails.main_contract?.start_date }}
                  </span>
                  <span v-else>N/A</span>
                </font>
                <font
                  v-else
                  class="font-my-style"
                >
                  N/A
                </font>
              </p>
              <p class="contract-content">
                Planned End Date:- <font
                  v-if="projectContractDetails.main_contract !== null"
                  class="font-my-style"
                >
                  <span v-if="projectContractDetails.main_contract?.planned_completion_date !== null">
                    {{ projectContractDetails.main_contract?.planned_completion_date }}
                  </span>
                  <span
                    v-else
                    class="font-my-style"
                  >N/A</span>
                </font>
                <font
                  v-else
                  class="font-my-style"
                >
                  N/A
                </font>
              </p>
              <p class="contract-content">
                Actual End Date:- <font
                  v-if="projectContractDetails.main_contract !== null"
                  class="font-my-style"
                >
                  <span v-if="projectContractDetails.main_contract?.actual_completion_date !== null">
                    {{ projectContractDetails.main_contract?.actual_completion_date }}
                  </span>
                  <span
                    v-else
                    class="font-my-style"
                  >N/A</span>
                </font>
                <font
                  v-else
                  class="font-my-style"
                >
                  N/A
                </font>
              </p>
              <p class="contract-content">
                Projected Work Hours:- <font
                  v-if="projectContractDetails.total_quantity !== null"
                  class="font-my-style"
                >
                  {{ projectContractDetails.total_quantity }} Hrs
                </font>
                <font
                  v-else
                  class="font-my-style"
                >
                  N/A
                </font>
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContractItemsList from './ContractItemsList';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import ContractCheckIns from './ContractCheckIns.vue';
import global from 'src/mixins/global';

  export default {
    name: 'ContractSecondNavDrawer',
    components: {
      'contract-items-list': ContractItemsList,
      'centre-spinner': spinner,
      'contract-check-ins': ContractCheckIns,
    },
    mixins: [global],
    data () {
      return {
        selectedItem: -1,
        showContractDetails: false,
        loading: false,
        showContractItemsList: true,
        showContractCheckInsList: false,
        tabs: null,
      };
    },
    computed: {
      projectContractDetails () {
        return this.$store.getters['projects/getProjectContractDetails'];
      },
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
    },
    async mounted () {
      this.selectedItem = 0;
      await this.getProjectContractDetails();
    },
    methods: {
      back () {
        this.$store.dispatch('projects/storeProjectSecondNavDrawerTab', {
          toTab: 5,
        });
        const projectId = this.$route.params.projectId;
        this.$router.push(`/projects/${projectId}/details`);
      },
      async getProjectContractDetails () {
        this.loading = true;
        const projectContractId = this.$route.params.contractId;
        await this.$store.dispatch('projects/fetchProjectContractDetails', {
          projectContractId: projectContractId,
        }).then(() => {
        this.showContractDetails = true;
        this.contractItemsList = this.projectContractDetails.budget_items;
        });
        this.loading = false;
        },
      getContractItemsList () {
        this.showContractItemsList = true;
        this.showContractCheckInsList = false;
      },
      getCheckInsList () {
        this.showContractItemsList = false;
        this.showContractCheckInsList = true;
      },
      getDifferenceColor (val) {
        if (val === 0) {
          return '#2E7D32';
        }
        return '#C62828';
      },
    },
  };
</script>

<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.my-back-btn-style {
  margin-bottom: -20px;
}
.project-progress-header{
  margin-top: 25px;
  margin-bottom: -20px;
}
.project-progress-title{
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  color: #37474F;
  font-size: 18px;
  display: flex;
  gap: 20px;
}
.progress-bar-style{
  width: 40%;
  margin-top: 6px;
}
.percentage-style{
  font-size: 15px;
}
@media (max-width: 959px) {
  .my-back-btn-style {
    margin-bottom: 0px;
    margin-left: 0px !important;
  }
  .contract-card {
    margin-left: -20px;
  }
  .contract-date-card {
    margin-top: -20px;
    margin-left: -20px;
  }
  .contract-cost-card {
    margin-top: -20px;
    margin-left: -20px;
  }
  .project-header {
    margin-left: 20px;
  }
}
@media (min-width: 959px) and (max-width: 1245px) {
  .font-my-style {
  font-weight: bold;
  font-size: 12px !important;
  color: #37474F;
  }
  .contract-content {
  font-size: 12px !important;
  font-weight: bold;
  color: #546E7A;
  margin-left: 12px !important;
  line-height: 0.8;
  }
}
.drawer-style {
  font-size:25px;
  color:#37474F;
  font-weight:bold;
}
.drawer-item-style {
  font-size:14px !important;
  color:#37474F !important;
  font-weight:bold !important;
}
.contract-content {
  font-size: 14px;
  font-weight: bold;
  color: #546E7A;
  margin-left: 15px;
  line-height: 0.8;
}
.font-my-style {
  font-weight: bold;
  font-size: 14px;
  color: #37474F;
}
.difference-font {
  font-weight: bold;
  font-size: 14px;
}
.contract-card {
  background: #e2d9f7;
  border: 1px solid #a077ff !important;
  padding: 30px;
  cursor: default;
  border: none;
  border-radius: 10px !important;
  box-shadow: none !important;
  margin: 0px !important;
}
.contract-cost-card {
  background: #ECEFF1;
  padding:20px;
  cursor: default;
  margin: 0px !important;
}
.contract-date-card {
  background: #cfe5eb;
  border: 1px solid #2bc6f1 !important;
  padding: 30px;
  cursor: default;
  border: none;
  border-radius: 10px !important;
  box-shadow: none !important;
  margin: 0px !important;
}
.project-header {
  margin-top: 25px;
  margin-bottom: -20px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  color: #37474F;
  font-size: 18px;
}
.project-name {
  color: #FF3700;
}
.cost-items {
  margin-left: 25px;
}
.currency-style {
  color: #FF3700;
  font-family: 'Times New Roman', Times, serif;
  font-size: 12px;
}
.contract-header-sec{
  padding-bottom: 50px;
  border-bottom: 1px solid #ccc;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-contracts {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.contract-list-card {
  box-shadow: none !important;
}
.table-contracts tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-contracts tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.action-btn {
  background: #fff !important;
}
.budget-card {
  background: #e2d9f7;
  border: 1px solid #a077ff !important;
  padding: 30px;
  cursor: default;
  border: none;
  border-radius: 10px !important;
  box-shadow: none !important;
  margin: 0px !important;
}
.budget-cost-card {
  background: #cfe5eb;
  border: 1px solid #2bc6f1 !important;
  padding: 30px;
  cursor: default;
  border: none;
  border-radius: 10px !important;
  box-shadow: none !important;
  margin: 0px !important;
}
.budget-date-card {
  background: #ffe0bb;
  border: 1px solid #c57d27 !important;
  padding: 30px;
  cursor: default;
  border: none;
  border-radius: 10px !important;
  box-shadow: none !important;
  margin: 0px !important;
}
.budget-card-small{
  border-radius: 10px;
  padding: 20px;
}
.budget-card-small-icon {
  margin-right: 10px;
  background: #ccc;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.budget-card-small h1{
  font-size: 24px;
  font-weight: bold;
}
.budget-card-small h4 {
  font-size: 14px;
  color: #aaa;
  font-weight: normal;
}
.budget-card-small-one {
  border: 1px solid #5AB3D9 !important;
}
.budget-card-small-one-icon {
  background:#5AB3D9 !important;
}
.budget-card-small-two {
border: 1px solid #8DC548  !important;
}
.budget-card-small-two-icon {
background: #E3FBC5 !important;
}
.budget-card-small-three {
border: 1px solid #6E94CE  !important;
}
.budget-card-small-three-icon {
background: #96BDF8 !important;
}
.budget-card-small-four {
border: 1px solid #96F8E6  !important;
}
.budget-card-small-four-icon {
background: #96F8E6 !important;
}
.premium-style {
  font-size:25px;
  color:#006883!important;
  font-weight:bold;
}
.premium-container{
  background-color: #ffffff!important;
  border-radius: 50%;
}
</style>
